import { useFragment } from '@apollo/client';
import {
  ProductAreaCategoryFragment,
  ProductAreaFragment,
  ProductAreasCategoriesFragmentDoc,
  ProductAreasFragmentDoc,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useWorkspaceContext } from '../../contexts/workspaceContext';

type Categories = (Pick<ProductAreaCategoryFragment, '__typename' | 'value'> &
{ id: string | null; productAreas: ProductAreaFragment[] })[];

export const useProductAreasCategories = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const areasCategories = useFragment({
    fragment: ProductAreasCategoriesFragmentDoc,
    fragmentName: 'ProductAreasCategories',
    from: productId,
  });
  const areas = useFragment({
    fragment: ProductAreasFragmentDoc,
    fragmentName: 'ProductAreas',
    from: productId,
  });
  const categories: Categories = [
    {
      __typename: 'ProductAreaCategory',
      id: null,
      value: 'No category',
      productAreas: nodeToArray(areas.complete ? areas.data.productAreas : null)
        .filter(a => !a.category?.id)
        .sort((a, b) => a.value.localeCompare(b.value)),
    },
    ...nodeToArray(areasCategories.complete ? areasCategories.data.productAreaCategories : null)
      .sort((a, b) => a.value.localeCompare(b.value))
      .map(category => {
        return {
          __typename: category.__typename,
          id: category.id,
          value: category.value,
          productAreas: nodeToArray(category.productAreas).sort((a, b) => a.value.localeCompare(b.value)),
        };
      }),
  ];
  return {
    areas: categories
      .flatMap(category => category.productAreas)
      .sort((a, b) => a.value.localeCompare(b.value)),
    categories,
  };
};