import { useFragment } from '@apollo/client';
import { DocCoverFragmentDoc } from '@cycle-app/graphql-codegen';
import { SelectPanel } from '@cycle-app/ui';
import { TrashIcon, MoreHorizIcon, ImageIcon } from '@cycle-app/ui/icons';
import { delay } from '@cycle-app/utilities/src/utils/async.utils';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { DocDeleteWarningModal } from 'src/components/DocDeleteWarningModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { closeQuoteModal, getQuoteIdParam } from 'src/hooks/modals/useQuoteModal';
import { navigate } from 'src/hooks/useNavigate';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { Layer } from 'src/types/layers.types';
import { getEditCoverAction } from 'src/utils/doc.util';
import { getPageId } from 'src/utils/routing.utils';

export const QuoteOptions = ({
  docId, className, 
}: {
  docId: string;
  className?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { updateDocCover } = useDocCoverMutations(docId);
  const coverInputRef = useRef<HTMLInputElement | null>(null);
  
  const coverUrl = useFragment({
    fragment: DocCoverFragmentDoc,
    from: docId,
  }).data.cover?.url;

  return (
    <>
      <ToggleDropdown
        layer={Layer.DropdownModalZ3}
        placement="bottom-end"
        withWrapper={false}
        button={props => (
          <button
            className={twJoin(
              'btn-tertiary btn-square text-disabled hover:text-primary',
              props['data-active'] && 'btn-hover text-primary',
              className,
            )}
            ref={props.ref}
            onClick={props.onClick}
          >
            <MoreHorizIcon />
          </button>
        )}
        content={props => (
          <SelectPanel
            hideSearch
            onOptionChange={option => {
              props.hide();
              option.onSelect?.();
            }}
            options={[{
              value: 'edit-cover',
              label: getEditCoverAction(coverUrl),
              icon: <ImageIcon />,
              onSelect: () => coverInputRef.current?.click(),
            }, {
              value: 'delete',
              label: 'Delete quote',
              icon: <TrashIcon />,
              variant: 'danger',
              onSelect: () => {
                if (!getPermission().canDeleteInsight) {
                  setLimitationsModal({ action: 'INSIGHT_DELETE' });
                  return;
                }
                setModalOpen(true);
              },
            }]}
          />
        )}
      />


      <CoverImageInputFile
        ref={coverInputRef}
        onCoverChanged={file => updateDocCover(file, { optimistic: true })}
      />

      {modalOpen && (
        <DocDeleteWarningModal
          docId={docId}
          onHide={() => setModalOpen(false)}
          onConfirm={async () => {
            if (getPageId() === PageId.Quote) {
              navigate(PageId.Main);
              return;
            }
            if (getQuoteIdParam()) {
              closeQuoteModal();
              await delay(200);
            }
          }}
        />
      )}
    </>
  );
};