import { CustomerWithDoctypeCountFragment } from '@cycle-app/graphql-codegen/generated';
import { InfiniteScroll } from '@cycle-app/ui';
import { formatCount } from '@cycle-app/utilities';
import { useAtomValue } from 'jotai';
import { twJoin } from 'tailwind-merge';

import { CustomerLinkedProfiles } from 'src/components/CustomerProfile/CustomerLinkedProfiles';
import { CustomerActionsMenu } from 'src/components/CustomersList/CustomerActionsMenu';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';

import { searchAtom } from './customers.atoms';
import { CellCustomer, CellCustomerCompany, CellEmail } from './CustomersCells';
import { Table, Column } from './Table';

const columns: Column<CustomerWithDoctypeCountFragment>[] = [{
  id: 'name',
  header: <span className="px-2">Name</span>,
  cell: customer => <CellCustomer customer={customer} />,
  className: 'w-1/5 min-w-48',
}, {
  id: 'company',
  header: <span className="px-2">Company</span>,
  cell: customer => <CellCustomerCompany company={customer.company} />,
  className: 'w-1/5 min-w-48',
}, {
  id: 'email',
  header: <span className="px-1.5">Email</span>,
  cell: customer => <CellEmail email={customer.email} />,
  className: 'w-1/4 min-w-48',
}, {
  id: 'quoteCount',
  header: '# quotes',
  cell: customer => formatCount(customer.nbInsights ?? 0),
  className: 'min-w-28',
}, {
  id: 'linkedProfiles',
  header: 'Linked profiles',
  cell: customer => <CustomerLinkedProfiles customer={customer} />,
  className: 'min-w-32',
}, {
  id: 'actions',
  header: '',
  cell: customer => <CustomerActionsMenu customer={customer} />,
  className: 'w-8',
}];

export const TablePeople = () => {
  const searchText = useAtomValue(searchAtom);
  const query = useCustomers({ defaultSearch: searchText });
  const empty = query.customers.length === 0;
  const loading = (empty && query.loading) || query.fetchingMore;
  const fetching = !empty && query.loading;
  return (
    <div className="relative size-full overflow-hidden bg-inherit pl-4">
      <InfiniteScroll
        isLoading={loading}
        hasMoreData={!!query.hasNextPage}
        loadMore={query.fetchNextPage}
        className={twJoin(
          'shy-scrollbar h-full bg-inherit pr-4',
          !query.hasNextPage && 'pb-40',
        )}
      >
        <Table
          columns={columns}
          data={query.customers}
          loading={loading}
          fetching={fetching}
          stickyRowHeader
        />
      </InfiniteScroll>
    </div>
  );
};
