import {
  FilterValueInput,
  PropertyFragment,
  FilterPropertyRuleFragment,
  SwimlaneFilterPropertyRuleFragment,
  FilterPropertyRuleAssigneeFragment,
  SwimlaneByFilterPropertyRuleAssigneeFragment,
  FilterPropertyRuleDoctypeFragment,
  FilterPropertyRuleSingleSelectFragment,
  SwimlaneByFilterPropertyRuleSingleSelectFragment,
  FilterPropertyRuleDocParentFragment,
  SwimlaneByFilterPropertyRuleDocParentFragment,
  FilterPropertyRuleCreatorFragment,
  SwimlaneByFilterPropertyRuleCreatorFragment,
  FilterPropertyRuleStatusFragment,
} from '@cycle-app/graphql-codegen';
import { update } from 'ramda';

import { defaultPageInfo } from 'src/utils/pagination.util';

// TODO: remove reference to claps once backend is ready
export const mappingPropertyFilterRule: Record<PropertyFragment['__typename'], FilterPropertyRuleFragment['__typename'] | null> = {
  AttributeSingleSelectDefinition: 'FilterPropertyRuleSingleSelect',
  AttributeMultiSelectDefinition: 'FilterPropertyRuleMultiSelect',
  AttributeTextDefinition: 'FilterPropertyRuleText',
  AttributeCheckboxDefinition: 'FilterPropertyRuleCheckbox',
  AttributeDateDefinition: 'FilterPropertyRuleDate',
  AttributeUrlDefinition: 'FilterPropertyRuleUrl',
  AttributeEmailDefinition: 'FilterPropertyRuleEmail',
  AttributeNumberDefinition: 'FilterPropertyRuleNumber',
  AttributePhoneDefinition: 'FilterPropertyRulePhone',
  AssigneeDefinition: 'FilterPropertyRuleAssignee',
  CreatorDefinition: 'FilterPropertyRuleCreator',
  DoctypeDefinition: 'FilterPropertyRuleDoctype',
  ClapDefinition: null,
  CreatedatDefinition: 'FilterPropertyRuleDate',
  CoverDefinition: 'FilterPropertyRuleText',
  DocidDefinition: 'FilterPropertyRuleText',
  DoctitleDefinition: 'FilterPropertyRuleText',
  ParentDefinition: 'FilterPropertyRuleDocParent',
  // TODO: put the filterPropertyRule typename when awailable
  ChildrenDefinition: null,
  BuiltInCustomerDefinition: null, // TO REPLACE
  BuiltInCompanyDefinition: null,
  BuiltInInsightDefinition: null,
  StatusDefinition: null,
  SourceDefinition: null, // TO REPLACE
  ProductAreaDefinition: null, // TO REPLACE
  ProductAreaCategoryDefinition: null, // TO REPLACE
  CommentDefinition: null, // TO REPLACE
  BuiltInReleaseDefinition: null,
  LinearAutomationDefinition: null,
  BuiltInAiStateDefinition: null,
};

// TODO: remove reference to claps once backend is ready
export const mappingSwimlanePropertyFilterRule: Record<
PropertyFragment['__typename'],
SwimlaneFilterPropertyRuleFragment['__typename'] | null
> = {
  AttributeSingleSelectDefinition: 'SwimlaneByFilterPropertyRuleSingleSelect',
  AttributeMultiSelectDefinition: 'SwimlaneByFilterPropertyRuleMultiSelect',
  AttributeTextDefinition: 'SwimlaneByFilterPropertyRuleText',
  AttributeCheckboxDefinition: 'SwimlaneByFilterPropertyRuleCheckbox',
  AttributeDateDefinition: 'SwimlaneByFilterPropertyRuleDate',
  AttributeUrlDefinition: 'SwimlaneByFilterPropertyRuleUrl',
  AttributeEmailDefinition: 'SwimlaneByFilterPropertyRuleEmail',
  AttributeNumberDefinition: 'SwimlaneByFilterPropertyRuleNumber',
  AttributePhoneDefinition: 'SwimlaneByFilterPropertyRulePhone',
  AssigneeDefinition: 'SwimlaneByFilterPropertyRuleAssignee',
  CreatorDefinition: 'SwimlaneByFilterPropertyRuleCreator',
  DoctypeDefinition: null,
  ClapDefinition: null,
  CreatedatDefinition: 'SwimlaneByFilterPropertyRuleDate',
  CoverDefinition: 'SwimlaneByFilterPropertyRuleText',
  DocidDefinition: 'SwimlaneByFilterPropertyRuleText',
  DoctitleDefinition: 'SwimlaneByFilterPropertyRuleText',
  ParentDefinition: 'SwimlaneByFilterPropertyRuleDocParent',
  ChildrenDefinition: 'SwimlaneByFilterPropertyRuleDocChildren',
  BuiltInCustomerDefinition: null,
  BuiltInCompanyDefinition: null,
  BuiltInInsightDefinition: null,
  StatusDefinition: null,
  SourceDefinition: null,
  ProductAreaDefinition: null,
  ProductAreaCategoryDefinition: null,
  CommentDefinition: null,
  BuiltInReleaseDefinition: null,
  LinearAutomationDefinition: null,
  BuiltInAiStateDefinition: null,
};

export function getOptimisticNewFilter<FilterTypename>(
  property: PropertyFragment,
  typeNameMapping: Record<typeof property.__typename, FilterTypename>,
) {
  const userEmptyRuleOperator = {
    __typename: 'RuleUserMultipleValues',
    values: {
      __typename: 'FilterPropertyRuleSelectableValuesUserConnection',
      edges: [],
    },
    operator: 'IS',
  };

  return {
    __typename: typeNameMapping[property.__typename],
    id: 'temp-id',
    isDeletable: false,
    isEditable: false,
    ...property.__typename === 'CreatorDefinition' && ({
      creatorRule: userEmptyRuleOperator,
    }),
    ...property.__typename === 'AssigneeDefinition' && ({
      assigneeRule: userEmptyRuleOperator,
    }),
    ...property.__typename === 'ParentDefinition' && ({
      docParentRule: {
        __typename: 'RuleDocParent',
        operator: 'IS',
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesDocConnection',
          edges: [],
          pageInfo: defaultPageInfo,
        },
      },
    }),
    ...property.__typename === 'AttributeSingleSelectDefinition' && ({
      attribute: property,
      singleSelectRule: {
        __typename: 'RuleSingleSelectMultipleValues',
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
          edges: [],
        },
        operator: 'IS',
      },
    }),
    ...property.__typename === 'AttributeMultiSelectDefinition' && ({
      attribute: property,
      multiSelectRule: {
        __typename: 'RuleMultiSelectMultipleValues',
        operator: 'IS',
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
          edges: [],
        },
        selectedValues: {
          __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
          edges: [],
        },
        availableValues: {
          __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
          edges: [],
        },
      },
    }),
    ...property.__typename === 'AttributeCheckboxDefinition' && ({
      attribute: property,
      checkboxOperator: 'IS_TRUE',
    }),
    ...property.__typename === 'AttributeEmailDefinition' && ({
      attribute: property,
      emailRule: {
        __typename: 'RuleEmailMultipleValues',
        operator: 'IS',
        values: {
          __typename: 'AttributeEmailValuesConnection',
          edges: [],
        },
      },
    }),
    ...property.__typename === 'AttributeTextDefinition' && ({
      attribute: property,
      textRule: {
        __typename: 'RuleTextMultipleValues',
        operator: 'IS',
        values: {
          __typename: 'AttributeTextValuesConnection',
          edges: [],
        },
      },
    }),
    ...property.__typename === 'AttributePhoneDefinition' && ({
      attribute: property,
      phoneRule: {
        __typename: 'RulePhoneMultipleValues',
        operator: 'IS',
        values: {
          __typename: 'AttributePhoneValuesConnection',
          edges: [],
        },
      },
    }),
    ...property.__typename === 'AttributeUrlDefinition' && ({
      attribute: property,
      urlRule: {
        __typename: 'RuleUrlMultipleValues',
        operator: 'IS',
        values: {
          __typename: 'AttributeUrlValuesConnection',
          edges: [],
        },
      },
    }),
  };
}

export function getOptimisticFilterWithAddedValue<Rule extends FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment>(
  filter: Rule,
  newValue: FilterValueInput,
): Rule {
  if (filter.__typename === 'FilterPropertyRuleAssignee' || filter.__typename === 'SwimlaneByFilterPropertyRuleAssignee') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const optimisticAssigneeFilter: FilterPropertyRuleAssigneeFragment | SwimlaneByFilterPropertyRuleAssigneeFragment = {
      ...filter,
      assigneeRule: {
        ...filter.assigneeRule,
        ...filter.assigneeRule.__typename === 'RuleUserMultipleValues' && {
          values: {
            __typename: 'FilterPropertyRuleSelectableValuesUserConnection',
            edges: filter.assigneeRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value.id)
              ? {
                ...edge,
                node: {
                  ...edge.node,
                  selected: true,
                },
              }
              : edge)),
          },
        },
      },
    };
    return optimisticAssigneeFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleCreator' || filter.__typename === 'SwimlaneByFilterPropertyRuleCreator') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const optimisticCreatorFilter: FilterPropertyRuleCreatorFragment | SwimlaneByFilterPropertyRuleCreatorFragment = {
      ...filter,
      creatorRule: {
        ...filter.creatorRule,
        ...filter.creatorRule.__typename === 'RuleUserMultipleValues' && {
          values: {
            __typename: 'FilterPropertyRuleSelectableValuesUserConnection',
            edges: filter.creatorRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value.id)
              ? {
                ...edge,
                node: {
                  ...edge.node,
                  selected: true,
                },
              }
              : edge)),
          },
        },
      },
    };
    return optimisticCreatorFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleDoctype') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const optimisticDoctypeFilter: FilterPropertyRuleDoctypeFragment = {
      ...filter,
      doctypeRule: {
        ...filter.doctypeRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesDoctypeConnection',
          edges: filter.doctypeRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value?.id ?? '')
            ? {
              ...edge,
              node: {
                ...edge.node,
                selected: true,
              },
            }
            : edge)),
        },
      },
    };
    return optimisticDoctypeFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleSingleSelect' || filter.__typename === 'SwimlaneByFilterPropertyRuleSingleSelect') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const optimisticSingleSelectFilter: FilterPropertyRuleSingleSelectFragment | SwimlaneByFilterPropertyRuleSingleSelectFragment = {
      ...filter,
      singleSelectRule: {
        ...filter.singleSelectRule,
        ...filter.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues' && {
          values: {
            __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
            edges: filter.singleSelectRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value.id)
              ? {
                ...edge,
                node: {
                  ...edge.node,
                  selected: true,
                },
              }
              : edge)),
          },
        },
      },
    };
    return optimisticSingleSelectFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleDocParent' || filter.__typename === 'SwimlaneByFilterPropertyRuleDocParent') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const firstSelectedFilterValue = (filter.docParentRule.__typename === 'RuleDocParentMultipleValues'
      ? filter.docParentRule.values.edges.find(({ node }) => newSelectedIds?.includes(node.value.id))
      : undefined);

    const optimisticDocParentFilter: FilterPropertyRuleDocParentFragment | SwimlaneByFilterPropertyRuleDocParentFragment = {
      ...filter,
      docParentRule: {
        ...filter.docParentRule,
        ...filter.docParentRule.__typename === 'RuleDocParentMultipleValues' && {
          selectedValues: {
            __typename: 'FilterPropertyRuleSelectableValuesDocConnection',
            edges: [
              ...filter.docParentRule.selectedValues.edges,
              ...firstSelectedFilterValue
                ? [{
                  __typename: 'FilterPropertyRuleSelectableValueDocEdge',
                  ...firstSelectedFilterValue,
                  node: {
                    __typename: 'FilterPropertyRuleSelectableValueDoc',
                    ...firstSelectedFilterValue.node,
                    selected: true,
                  },
                } as const] : [],
            ],
          },
          values: {
            __typename: 'FilterPropertyRuleSelectableValuesDocConnection',
            edges: filter.docParentRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value.id)
              ? {
                ...edge,
                node: {
                  ...edge.node,
                  selected: true,
                },
              }
              : edge)),
          },
        },
      },
    };
    return optimisticDocParentFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleStatus') {
    const newSelectedIds = getListValues(newValue);
    if (!newSelectedIds) return filter;

    const optimisticStatusFilter: FilterPropertyRuleStatusFragment = {
      ...filter,
      statusRule: {
        ...filter.statusRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesStatusConnection',
          edges: filter.statusRule.values.edges.map((edge) => (newSelectedIds?.includes(edge.node.value?.id ?? '')
            ? {
              ...edge,
              node: {
                ...edge.node,
                selected: true,
              },
            }
            : edge)),
        },
      },
    };
    return optimisticStatusFilter as Rule;
  }

  return filter;
}

export function getOptimisticFilterWithRemovedValue<Rule extends FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment>(
  filter: Rule,
  valueIdToRemove: string,
): Rule {
  if (
    (filter.__typename === 'FilterPropertyRuleAssignee' && filter.assigneeRule.__typename === 'RuleUserMultipleValues') ||
    (filter.__typename === 'SwimlaneByFilterPropertyRuleAssignee' && filter.assigneeRule.__typename === 'RuleUserMultipleValues')
  ) {
    const indexToUnselect = filter.assigneeRule.values.edges.findIndex(({ node }) => node.value.id === valueIdToRemove);
    const nodeToUnselect = filter.assigneeRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticAssigneeFilter: FilterPropertyRuleAssigneeFragment | SwimlaneByFilterPropertyRuleAssigneeFragment = {
      ...filter,
      assigneeRule: {
        ...filter.assigneeRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesUserConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueUserEdge',
            node: unselectedValue,
          }, filter.assigneeRule.values.edges),
        },
      },
    };
    return optimisticAssigneeFilter as Rule;
  }

  if (
    (filter.__typename === 'FilterPropertyRuleCreator' && filter.creatorRule.__typename === 'RuleUserMultipleValues') ||
    (filter.__typename === 'SwimlaneByFilterPropertyRuleCreator' && filter.creatorRule.__typename === 'RuleUserMultipleValues')
  ) {
    const indexToUnselect = filter.creatorRule.values.edges.findIndex(({ node }) => node.value.id === valueIdToRemove);
    const nodeToUnselect = filter.creatorRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticCreatorFilter: FilterPropertyRuleCreatorFragment | SwimlaneByFilterPropertyRuleCreatorFragment = {
      ...filter,
      creatorRule: {
        ...filter.creatorRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesUserConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueUserEdge',
            node: unselectedValue,
          }, filter.creatorRule.values.edges),
        },
      },
    };
    return optimisticCreatorFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleDoctype') {
    const indexToUnselect = filter.doctypeRule.values.edges.findIndex(({ node }) => node.value?.id === valueIdToRemove);
    const nodeToUnselect = filter.doctypeRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticDoctypeFilter: FilterPropertyRuleDoctypeFragment = {
      ...filter,
      doctypeRule: {
        ...filter.doctypeRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesDoctypeConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueDoctypeEdge',
            node: unselectedValue,
          }, filter.doctypeRule.values.edges),
        },
      },
    };
    return optimisticDoctypeFilter as Rule;
  }

  if (
    (filter.__typename === 'FilterPropertyRuleSingleSelect' && filter.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues') ||
    (filter.__typename === 'SwimlaneByFilterPropertyRuleSingleSelect' && filter.singleSelectRule.__typename === 'RuleSingleSelectMultipleValues')
  ) {
    const indexToUnselect = filter.singleSelectRule.values.edges.findIndex(({ node }) => node.value.id === valueIdToRemove);
    const nodeToUnselect = filter.singleSelectRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticSingleSelectFilter: FilterPropertyRuleSingleSelectFragment | SwimlaneByFilterPropertyRuleSingleSelectFragment = {
      ...filter,
      singleSelectRule: {
        ...filter.singleSelectRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesTextConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueTextEdge',
            node: unselectedValue,
          }, filter.singleSelectRule.values.edges),
        },
      },
    };
    return optimisticSingleSelectFilter as Rule;
  }

  if (
    (filter.__typename === 'FilterPropertyRuleDocParent' && filter.docParentRule.__typename === 'RuleDocParentMultipleValues') ||
    (filter.__typename === 'SwimlaneByFilterPropertyRuleDocParent' && filter.docParentRule.__typename === 'RuleDocParentMultipleValues')
  ) {
    const indexToUnselect = filter.docParentRule.values.edges.findIndex(({ node }) => node.value?.id === valueIdToRemove);
    const nodeToUnselect = filter.docParentRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticDocParentFilter: FilterPropertyRuleDocParentFragment | SwimlaneByFilterPropertyRuleDocParentFragment = {
      ...filter,
      docParentRule: {
        ...filter.docParentRule,
        selectedValues: {
          __typename: 'FilterPropertyRuleSelectableValuesDocConnection',
          edges: filter.docParentRule.selectedValues.edges.filter(({ node }) => node.id !== unselectedValue.id),
        },
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesDocConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueDocEdge',
            node: unselectedValue,
          }, filter.docParentRule.values.edges),
        },
      },
    };
    return optimisticDocParentFilter as Rule;
  }

  if (filter.__typename === 'FilterPropertyRuleStatus') {
    const indexToUnselect = filter.statusRule.values.edges.findIndex(({ node }) => node.value?.id === valueIdToRemove);
    const nodeToUnselect = filter.statusRule.values.edges[indexToUnselect]?.node;
    if (!nodeToUnselect) return filter;

    const unselectedValue = {
      ...nodeToUnselect,
      selected: false,
    };

    const optimisticStatusFilter: FilterPropertyRuleStatusFragment = {
      ...filter,
      statusRule: {
        ...filter.statusRule,
        values: {
          __typename: 'FilterPropertyRuleSelectableValuesStatusConnection',
          edges: update(indexToUnselect, {
            __typename: 'FilterPropertyRuleSelectableValueStatusEdge',
            node: unselectedValue,
          }, filter.statusRule.values.edges),
        },
      },
    };
    return optimisticStatusFilter as Rule;
  }

  return filter;
}

const getListValues = (value: FilterValueInput) => {
  if ('singleSelect' in value && 'IS' in value.singleSelect) {
    return value.singleSelect.IS;
  }
  if ('singleSelect' in value && 'IS_NOT' in value.singleSelect) {
    return value.singleSelect.IS_NOT;
  }
  if ('multiSelect' in value && 'IS' in value.multiSelect) {
    return value.multiSelect.IS;
  }
  if ('multiSelect' in value && 'IS_NOT' in value.multiSelect) {
    return value.multiSelect.IS_NOT;
  }

  return [];
};
