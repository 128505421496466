import { isPresent } from 'ts-is-present';
import { z } from 'zod';

import { QuoteFileProps } from './QuoteFile';

export const parseContentFiles = (html: string) => {
  const document = new DOMParser().parseFromString(html, 'text/html').documentElement;

  const images = [...document.querySelectorAll('img')]
    .map(getMediaFileProps)
    .filter(isPresent);

  const medias = [...document.querySelectorAll('audio-tagname, video-tagname')]
    .map(getMediaFileProps)
    .filter(isPresent);
  
  const others = [...document.querySelectorAll('react-component')]
    .map(getFileProps)
    .filter(isPresent);

  return {
    images,
    files: [...medias, ...others],
  };
};

const fileSchema = z.object({
  src: z.string(),
  title: z.string().nullable(),
  mime: z.string().nullable(),
  size: z.number().nullable(),
});
  
const getFileProps = (el: Element): QuoteFileProps | null => {
  const json = el.getAttribute('file');
  if (!json) return null;
  try {
    const data = fileSchema.parse(JSON.parse(json));
    return {
      src: data.src,
      name: data.title,
      mimeType: data.mime,
      size: data.size,
    };
  } catch {
    return null;
  }
};

const getMediaFileProps = (el: Element): QuoteFileProps | null => {
  const src = el.getAttribute('src');
  if (!src) return null;
  const title = el.getAttribute('title');
  return {
    src,
    name: title,
    mimeType: null,
    size: null,
  };
};
  