import { Color, HeroSection } from '@cycle-app/graphql-codegen';
import { Input, SelectPanel } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { Controller } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { useChangelogBuilderTheme } from '../ChangelogBuilderThemeProvider';
import { shipOptions } from '../constants/shipOptions';
import { ChangelogDropdown, ChangelogDropdownContent, ChangelogDropdownDivider } from '../shared/ChangelogDropdown';
import { useThemeVariant } from '../shared/useThemeVariant';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

export function ChangelogBuilderContentHeader() {
  const { watch } = useChangelogBuilderForm();
  const { isDarkTheme } = useChangelogBuilderTheme();

  const heroSectionClasses = useThemeVariant(watch('heroSection'));

  const shipBadgeColor = watch('shipBadgeColor');
  const subtitle = watch('subtitle');
  const shipIndex = subtitle.toLocaleLowerCase().indexOf('ship');
  const hasShip = shipIndex >= 0;

  return (
    <ChangelogDropdown
      content={({ hide }) => (
        <ChangelogDropdownContent
          title="Header"
          hide={hide}
        >
          <EditContentPopover hasShip={hasShip} />
        </ChangelogDropdownContent>
      )}
    >
      <div className="text-center">
        <h1 className={twJoin(
          'font-bold text-[var(--changelog-headers-color)]',
          heroSectionClasses({
            S: 'mt-8 text-[32px] tablet:text-[40px]/[44px]',
            M: 'mt-10 text-[36px] tablet:text-[48px]/[57.6px]',
            L: 'mt-12 text-[41px] tablet:text-[62px]/[68px]',
            XL: 'mt-12 text-[73px] tablet:text-[80px]/[91px]',
          }),
        )}
        >
          {watch('title')}
        </h1>
        <p className={twJoin(
          'mt-4 font-medium text-[var(--changelog-body-color)]',
          heroSectionClasses({
            S: 'mt-3 text-base',
            M: 'mt-3 tablet:mt-2 text-base tablet:text-lg',
            L: 'mt-4 text-xl',
            XL: 'mt-4 text-xl tablet:text-2xl',
          }),
        )}
        >
          {hasShip ? (
            <>
              <span>{subtitle.substring(0, shipIndex)}</span>
              <img
                src={isDarkTheme ? shipOptions[shipBadgeColor].darkBadge : shipOptions[shipBadgeColor].lightBadge} alt="Ship badge"
                className="mx-1 inline-block h-[2.3em] align-middle drop-shadow"
                loading="lazy"
              />
              <span>{subtitle.substring(shipIndex).replace('ship', '')}</span>
            </>
          ) : (
            subtitle
          )}
        </p>
      </div>
    </ChangelogDropdown>
  );
}

const heroOptions = [
  {
    value: HeroSection.S,
    label: 'Small',
  },
  {
    value: HeroSection.M,
    label: 'Medium',
  },
  {
    value: HeroSection.L,
    label: 'Large',
  },
  {
    value: HeroSection.Xl,
    label: 'Extra large',
  },
];

const shipItems = [
  Color.A,
  Color.B,
  Color.C,
  Color.D,
  Color.E,
  Color.F,
  Color.G,
  Color.H,
  Color.I,
  Color.J,
  Color.K,
].map((colorCode) => ({
  value: colorCode,
  color: shipOptions[colorCode].color,
}));

function EditContentPopover({ hasShip }: { hasShip: boolean }) {
  const {
    register, control,
  } = useChangelogBuilderForm();

  return (
    <>
      <Input
        autoFocus
        label="Title"
        {...register('title')}
      />
      <Input
        className="mt-4"
        label="Subtitle"
        {...register('subtitle')}
      />
      <ChangelogDropdownDivider className="my-4" />
      <div className="mb-2 font-medium">Size</div>
      <Controller
        control={control}
        name="heroSection"
        render={({ field }) => (
          <ToggleDropdown
            withWrapper={false}
            placement="bottom-start"
            layer={Layer.DropdownModal}
            content={(contentProps) => (
              <SelectPanel
                hideSearch
                selectedValue={field.value}
                options={heroOptions}
                onOptionChange={(payload) => {
                  field.onChange(payload.value);
                  contentProps.hide();
                }}
                style={{ width: `${contentProps.buttonRect?.width}px` }}
              />
            )}
            button={(buttonProps) => (
              <button
                ref={buttonProps.ref}
                type="button"
                  // eslint-disable-next-line max-len
                className="group flex w-full cursor-pointer items-center justify-between rounded-lg border border-primary px-3 py-2 text-left hover:bg-grey-100 dark:hover:bg-grey-800"
                onClick={buttonProps.onClick}
              >
                {heroOptions.find((option) => option.value === field.value)?.label ?? 'Select'}
                <DownIcon className="text-secondary group-aria-expanded:rotate-180" />
              </button>
            )}
          />
        )}
      />
      {hasShip && (
        <>
          <ChangelogDropdownDivider className="my-4" />
          <div className="mb-2 mt-4 font-medium">Ship badge color</div>
          <Controller
            control={control}
            name="shipBadgeColor"
            render={({ field }) => (
              <div className="grid grid-cols-11 place-items-center">
                {shipItems.map((shipOption, shipOptionIndex) => (
                  <label key={shipOptionIndex}>
                    <div className="cursor-pointer rounded-full border border-transparent p-1 hover:border-grey-300 has-[:checked]:border-grey-500">
                      <input
                        type="radio"
                        value={shipOption.value}
                        name="shipBadgeColor"
                        checked={field.value === shipOption.value}
                        onChange={field.onChange}
                        className="sr-only"
                      />
                      <div className="size-3 rounded-full border border-grey-600/10 shadow-sm" style={{ backgroundColor: shipOption.color }} />
                    </div>
                  </label>
                ))}
              </div>
            )}
          />
        </>
      )}
    </>
  );
}
