import { Icon } from '@cycle-app/ui';
import { ReactNode, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { Placement } from 'tippy.js';

import { DropdownLayer } from 'src/components/DropdownLayer';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { useActiveChangelogId } from 'src/reactives/changelogBuilder.reactive';
import { Layer } from 'src/types/layers.types';


export function ChangelogDropdown({
  dropdownId,
  children,
  empty,
  smallContainer,
  content,
  onClick,
  placement,
}: {
  dropdownId?: string;
  children: ReactNode;
  empty?: boolean;
  smallContainer?: boolean;
  content: ((payload: { hide: VoidFunction }) => ReactNode);
  onClick?: VoidFunction;
  placement?: Placement;
}) {
  const [{ activeId }, setActiveIdState] = useActiveChangelogId();
  const [renderPopoverOpen, setRenderPopoverOpen] = useState(false);
  const offsetAdapter = useTippyOffsetAdapter();

  const setActiveId = (isActive: boolean) => {
    if (renderPopoverOpen) return;
    setActiveIdState({ activeId: isActive ? dropdownId : undefined });
  };

  const closePopover = () => {
    setRenderPopoverOpen(false);
    setActiveIdState({ activeId: undefined });
  };

  return (
    <DropdownLayer
      placement={placement ?? 'bottom-start'}
      controlled
      visible={renderPopoverOpen}
      hide={() => closePopover()}
      interactive
      withPortal
      withWrapper={false}
      animation
      offset={smallContainer ? [-4, 8] : [-12, 16]}
      zIndex={mappingZindex[Layer.Dropdown]}
      content={content({ hide: () => closePopover() })}
      {...offsetAdapter.tippyProps}
    >
      <div
        className="group relative cursor-pointer hover:z-10 aria-expanded:z-10"
        onClick={() => {
          onClick?.();
          setRenderPopoverOpen(true);
        }}
        onPointerEnter={() => setActiveId(true)}
        onPointerLeave={() => setActiveId(false)}
      >
        <div
          className={twJoin(
            // eslint-disable-next-line max-len
            'absolute z-0 border border-[var(--interaction-color-level-2)] bg-[var(--interaction-color-level-1)] opacity-0 transition-opacity duration-200 group-hover:opacity-100 group-aria-expanded:opacity-100',
            smallContainer ? '-inset-1 rounded-md' : '-inset-3 rounded-2xl',
            empty && 'opacity-100',
            renderPopoverOpen && 'opacity-100',
            dropdownId && (activeId === dropdownId) && 'opacity-100',
          )}
        />

        <div className="isolate">{children}</div>
      </div>
    </DropdownLayer>
  );
}

export function ChangelogDropdownContent({
  title,
  hide,
  children,
}: {
  title: ReactNode;
  hide?: VoidFunction;
  children: ReactNode;
}) {
  return (
    <div className="w-72 px-3">
      <div className="flex items-center justify-between gap-2 py-3 font-medium text-primary">
        {title}
        {hide && (
          <button
            className="btn-tertiary btn-sm btn-square"
            onClick={hide}
          >
            <Icon name="close" />
          </button>
        )}
      </div>
      <ChangelogDropdownDivider />
      <div className="py-4">
        {children}
      </div>
    </div>
  );
}

export function ChangelogDropdownDivider({ className }: { className?: string }) {
  return (
    <div className={twJoin('-mx-3 h-px bg-grey-200 dark:bg-grey-800', className)} />
  );
}
