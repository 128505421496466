import { Tag, Tooltip } from '@cycle-app/ui';
import { TagIcon, AddIcon } from '@cycle-app/ui/icons';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useDocBase } from 'src/hooks/api/useDoc';
import { Layer } from 'src/types/layers.types';

export const QuoteProperties = ({ docId }: { docId: string }) => {
  const { doc } = useDocBase(docId);
  const count = doc ? doc.attributes.edges.length + doc.productAreas.edges.length : 0;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Properties are displayed in a dropdown if the container is too small
  const [overflow, setOverflow] = useState(false);

  // Update the overflow state on container width change
  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver(([entry]) => {
      if (!entry || !contentRef.current) return;
      setOverflow(entry.contentRect.width < contentRef.current.getBoundingClientRect().width);
    });

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, []);

  // Update the overflow state on content change
  useEffect(() => {
    if (!containerRef.current || !contentRef.current) return;
    setOverflow(containerRef.current.getBoundingClientRect().width < contentRef.current.getBoundingClientRect().width);
  }, [count]);

  if (!doc) return null;

  const showDropdown = overflow && count > 0;

  return (
    <div
      ref={containerRef}
      className="relative -m-0.5 flex min-w-6 grow items-center justify-end overflow-hidden p-0.5"
    >
      {showDropdown && (
        <ToggleDropdown
          layer={Layer.DropdownModalZ3}
          content={(
            <DocPanelDocAttributes
              className="shy-scrollbar flex max-h-96 flex-col !items-start !gap-2 !p-2"
              doc={doc}
              layer={Layer.DropdownModalZ4}
              showDocId={false}
              showDoctype={false}
              showStatus={false}
              showLinear={false}
              showAiState={false}
              showRelease={false}
              showAddButton
              addButton={props => (
                <button
                  className={twJoin(
                    'btn-tertiary btn-sm h-5 font-normal hover:text-primary',
                    props.toggled && 'btn-hover text-primary',
                  )}
                  onClick={props.onClick}
                >
                  <AddIcon size={10} />
                  Add property
                </button>
              )}
            />
          )}
          button={props => (
            <Tag
              color="grey"
              icon={<TagIcon size={12} />}
              className="!font-medium !text-secondary"
              onClick={e => {
                e.stopPropagation();
                props.onClick(e);
              }}
            >
              {count} 
            </Tag>
          )}
        />
      )}
      
      <div
        className={twJoin(
          'flex w-0 flex-nowrap items-center justify-end',
          showDropdown && 'pointer-events-none opacity-0',
        )}
      >
        <DocPanelDocAttributes
          ref={contentRef}
          className="!flex-nowrap !overflow-visible"
          doc={doc}
          layer={Layer.DropdownModalZ3}
          showDocId={false}
          showDoctype={false}
          showStatus={false}
          showLinear={false}
          showAiState={false}
          showRelease={false}
          showAddButton
          addButton={props => (
            <Tooltip
              content="Add property"
              placement="top"
              withWrapper={false}
              withPortal
            >
              <button
                className={twJoin(
                  'btn-tertiary btn-square size-5 text-disabled hover:text-primary',
                  props.toggled && 'btn-hover text-primary',
                )}
                onClick={props.onClick}
                disabled={showDropdown}
              >
                <AddIcon size={12} />
              </button>
            </Tooltip>
          )}
        />
      </div>
    </div>
  );
};
