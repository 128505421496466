import { State, Modifier } from '@popperjs/core';

export const adjustPopperPosition: Modifier<State, { offset: number }>['fn'] = ({
  state, options,
}) => {
  if (!options.offset) return;
  const {
    rects, placement,
  } = state;

  // Get viewport dimensions
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  // Calculate overflow based on placement
  let overflowY = 0;
  let overflowX = 0;

  if (placement.includes('top') || placement.includes('bottom')) {
    overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;
  } else if (placement.includes('left') || placement.includes('right')) {
    overflowX = options.offset + rects.reference.x + rects.reference.width + rects.popper.width - viewportWidth;
  }

  // Adjust vertical position if needed and if placement is top/bottom
  if (overflowY > 0 && state.modifiersData?.popperOffsets?.y &&
      (placement.includes('top') || placement.includes('bottom'))) {
    // eslint-disable-next-line no-param-reassign
    state.modifiersData.popperOffsets.y = Math.max(
      options.offset,
      state.modifiersData.popperOffsets.y - overflowY,
    );
  }

  // Adjust horizontal position if needed and if placement is left/right
  if (overflowX > 0 && state.modifiersData?.popperOffsets?.x &&
      (placement.includes('left') || placement.includes('right'))) {
    // eslint-disable-next-line no-param-reassign
    state.modifiersData.popperOffsets.x = Math.max(
      options.offset,
      state.modifiersData.popperOffsets.x - overflowX,
    );
  }

  // Add scroll attribute if popper is larger than viewport in either dimension
  if (viewportHeight <= rects.popper.height || viewportWidth <= rects.popper.width) {
    state.elements.popper.setAttribute('data-scroll', 'true');
  }
};
