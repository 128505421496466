import { ChangelogDateStyle } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { format, type Locale } from 'date-fns';
import {
  enUS, fr, de, es, it, nl,
} from 'date-fns/locale';

import { ChangelogDropdown, ChangelogDropdownContent } from '../shared/ChangelogDropdown';
import { useChangelogBuilderForm } from '../useChangelogBuilderForm';

const displayedDates = [
  new Date(), // today
  new Date(new Date().setDate(new Date().getDate() - 7)), // last week
  new Date(new Date().setMonth(new Date().getMonth() - 1)), // last month
] as const;

type TranslateObject = { translations: Locale; format: string };

const localesWithFormats: Record<string, TranslateObject> = {
  en: {
    translations: enUS,
    format: 'MMM dd, yyyy',
  },
  de: {
    translations: de,
    format: 'dd. MMM yyyy',
  },
  fr: {
    translations: fr,
    format: 'dd MMM yyyy',
  },
  es: {
    translations: es,
    format: 'dd MMM yyyy',
  },
  it: {
    translations: it,
    format: 'dd MMM yyyy',
  },
  nl: {
    translations: nl,
    format: 'dd MMM yyyy',
  },
};

function formatDate(date: string | Date, locale: string = 'en') {
  const localeObject = localesWithFormats[locale] ?? localesWithFormats.en as TranslateObject;

  return format(new Date(date), localeObject.format, { locale: localeObject?.translations });
}


export function ChangelogBuilderContentDates() {
  const { watch } = useChangelogBuilderForm();
  const language = watch('language');
  const dateStyle = watch('dateStyle');

  return (
    <div className="sticky top-8 flex">
      <ChangelogDropdown
        content={({ hide }) => (
          <ChangelogDropdownContent title="Dates" hide={hide}>
            <DatesPopover />
          </ChangelogDropdownContent>
        )}
        empty={dateStyle === ChangelogDateStyle.Hidden}
      >
        <div className="w-28">
          {dateStyle === ChangelogDateStyle.Stacked && (
            <ul className="flex flex-col gap-2">
              {displayedDates.map((date, index) => (
                <li key={index} className="text-[var(--changelog-body-color)] first:mb-2 first:font-semibold">
                  {formatDate(date, watch('language'))}
                </li>
              ))}
            </ul>
          )}
          {dateStyle === ChangelogDateStyle.Single && (
            <div className="font-semibold text-[var(--changelog-body-color)]">
              {formatDate(displayedDates[0], language)}
            </div>
          )}
          {dateStyle === ChangelogDateStyle.Hidden && (
            <div className="flex h-24 items-center justify-center gap-2 text-[var(--changelog-body-color)]">
              <Icon name="plus" />
              Dates
            </div>
          )}
        </div>
      </ChangelogDropdown>
    </div>
  );
}

const options = [
  {
    value: ChangelogDateStyle.Stacked,
    label: 'Stacked',
    illustration: (
      <div className="w-full space-y-0.5">
        <div className="h-[3px] rounded-full bg-current" />
        <div className="h-[3px] rounded-full bg-current" />
        <div className="h-[3px] rounded-full bg-current" />
      </div>
    ),
  },
  {
    value: ChangelogDateStyle.Single,
    label: 'Single',
    illustration: (
      <div className="h-[3px] w-full rounded-full bg-current" />
    ),
  },
  {
    value: ChangelogDateStyle.Hidden,
    label: 'Hidden',
    illustration: null,
  },
];

function DatesPopover() {
  const { register } = useChangelogBuilderForm();

  return (
    <>
      <div className="mb-2 font-medium">Style</div>
      {options.map((option) => (
        <label
          key={option.value}
          // eslint-disable-next-line max-len
          className="-mx-2 flex items-center gap-2 rounded px-2 py-1 hover:bg-grey-100 has-[:focus-visible]:bg-grey-100 dark:hover:bg-grey-800 dark:has-[:focus-visible]:bg-grey-800"
        >
          <input
            type="radio"
            className="size-4 text-cycle accent-current focus:outline-none"
            value={option.value}
            {...register('dateStyle')}
          />
          {option.label}

          {option.illustration && (
            <div className="ml-auto flex w-6 items-center justify-center gap-1 text-grey-300 dark:text-grey-700">
              {option.illustration}
            </div>
          )}
        </label>
      ))}
    </>
  );
}