import { AddIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { CreateDocModal } from 'src/app/Main/Board/CreateDocModal';
import { RoadmapViewSettingsModal } from 'src/components/RoadmapViewSettings';
import { ViewBreadcrumb } from 'src/components/ViewBreadcrumb';
import { ViewSettings, ViewActions, ShareBoard } from 'src/components/ViewHeader';
import { AddViewButton } from 'src/components/ViewHeader/AddViewButton';
import { ViewsList } from 'src/components/ViewsList';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useRoadmapSection } from 'src/hooks/roadmap/useRoadmapSection';
import { useMustAddRoadmapsTemplate } from 'src/hooks/useCanExploreRoadmapsTemplates';
import { useParentPage } from 'src/hooks/usePageId';
import { useBoardId, useBoardSlug } from 'src/hooks/usePathParams';
import { useRoadmapTour } from 'src/hooks/useRoadmapTour';
import { useGetPermission, useIsDesktop, useIsMobile } from 'src/reactives';
import { useBoardConfigAnalytics } from 'src/reactives/boardConfigAnalytics.reactive';
import { openRoadmapsTemplateModal } from 'src/reactives/roadmaps.reactive';

import {
  Title, Container, Header, TopRight, TopLeft, HeaderActionButton, HeaderActions, Action,
} from './Roadmap.styles';
import { InsightViewAnalyticsSettingsModal } from '../../InsightViewSettings/InsightViewAnalyticsSettingsModal';

type Props = {
  children: ReactNode;
};

export const Roadmap: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const boardSlug = useBoardSlug();
  const parent = useParentPage();
  if (parent !== 'roadmap') return null;
  if (!boardSlug) return <RoadmapViewsPage />;
  return <RoadmapViewPage>{children}</RoadmapViewPage>;
};

const RoadmapViewsPage = () => {
  const { open: openRoadmapTour } = useRoadmapTour();
  const mustAddRoadmapsTemplate = useMustAddRoadmapsTemplate();
  const isMobile = useIsMobile();
  const {
    isPermissionInit, canCreateView,
  } = useGetPermission();
  return (
    <>
      <Container $variant="primary">
        <Header>
          <TopLeft>
            <Title>
              Features
            </Title>

            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={openRoadmapTour}
            >
              <InfoIconOutline size={14} />
            </Action>
          </TopLeft>

          <TopRight>
            {!isMobile && isPermissionInit && canCreateView && (
              <AddViewButton sectionParent="roadmap" />
            )}
            <HeaderActions>
              {mustAddRoadmapsTemplate && (
                <HeaderActionButton
                  onClick={openRoadmapsTemplateModal}
                  tooltip="Explore advanced features"
                  tooltipPlacement="bottom"
                  $isNewInbox={false}
                >
                  <AddIcon />
                </HeaderActionButton>
              )}
            </HeaderActions>
          </TopRight>
        </Header>

        <ViewsList sectionParent="roadmap" />
      </Container>

      <BoardConfigContextProvider>
        <CreateDocModal />
      </BoardConfigContextProvider>
    </>
  );
};

const RoadmapViewPage: FC<Props> = ({ children }) => {
  const mustAddRoadmapsTemplate = useMustAddRoadmapsTemplate();
  const { isPermissionInit } = useGetPermission();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  return (
    <BoardConfigContextProvider>
      <Container>
        <Header>
          <ViewBreadcrumb sectionParent="roadmap" />

          <TopRight>
            {isDesktop && (
              <HeaderActions>
                {mustAddRoadmapsTemplate && (
                  <HeaderActionButton
                    onClick={openRoadmapsTemplateModal}
                    tooltip="Explore advanced features"
                    tooltipPlacement="bottom"
                    $isNewInbox={false}
                  >
                    <AddIcon />
                  </HeaderActionButton>
                )}
              </HeaderActions>
            )}

            {!isMobile && isPermissionInit && (
              <div className="flex items-center gap-1">
                <ShareBoard />
                <ViewSettings />
                <ViewActions />
              </div>
            )}
          </TopRight>
        </Header>

        {children}
      </Container>
      <SettingsModal />
      <SettingsAnalyticsModal />
    </BoardConfigContextProvider>
  );
};

const SettingsModal = () => {
  const { boards } = useRoadmapSection();
  const boardId = useBoardId();
  const boardWithConfig = boards.find(b => b.id === boardId);
  if (!boardWithConfig?.draftBoardConfig) return null;
  return (
    <RoadmapViewSettingsModal
      board={boardWithConfig}
      draftBoardConfigId={boardWithConfig.draftBoardConfig?.id || ''}
    />
  );
};

const SettingsAnalyticsModal = () => {
  const { boards } = useRoadmapSection();
  const boardId = useBoardId();
  const board = boards.find(b => b.id === boardId);
  const isOpen = useBoardConfigAnalytics();
  return (board && isOpen && (
    <InsightViewAnalyticsSettingsModal
      board={board}
    />
  )) || null;
};
