import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { useMemo } from 'react';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { history } from 'src/providers';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { getUrl } from 'src/utils/routing.utils';
import { SectionParent, getSectionIcon, getSectionName, getSectionPageId } from 'src/utils/section.utils';

import { SectionTooltip } from './SectionTooltip';
import { Caret, DoubleCaret, SectionButton } from './ViewBreadcrumb.styles';
import { BreadcrumbSize } from './ViewBreadcrumb.utils';

type Props = {
  size: BreadcrumbSize;
  sectionParent: SectionParent;
};

export const SectionDropdown = ({
  size, sectionParent,
}: Props) => (
  <>
    <ToggleDropdown
      placement="bottom-start"
      content={props => <SectionList hide={props.hide} />}
      button={props => (
        <SectionTooltip>
          <SectionButton
            $isSmall={size < 2}
            iconEnd={size > 1 && <DoubleCaret />}
            {...props}
          >
            {size < 2 ? <MoreHorizIcon /> : getSectionName(sectionParent)}
          </SectionButton>
        </SectionTooltip>
      )}
    />
    <Caret />
  </>
);

const SectionList = ({ hide }: { hide: VoidFunction }) => {
  const isRoadmapEnabled = useIsRoadmapsEnabled();
  const { navigate } = useNavigateToSettings();

  const sectionOptions = useMemo(() => {
    const sectionParents: SectionParent[] = ['inbox', 'insight'];
    if (isRoadmapEnabled) sectionParents.push('roadmap');

    const options: SelectOption<SectionParent>[] = sectionParents.map(sectionParent => ({
      icon: getSectionIcon(sectionParent),
      label: getSectionName(sectionParent),
      value: sectionParent,
      data: sectionParent,
    }));

    if (!isRoadmapEnabled) {
      options.push({
        icon: getSectionIcon('roadmap'),
        label: 'Try features',
        value: 'roadmap',
      });
    }

    return options;
  }, [isRoadmapEnabled]);

  return (
    <SelectPanel
      style={{ width: 244 }}
      hideSearch
      options={sectionOptions}
      onOptionChange={(option, event) => {
        hide();

        if (option.value === 'roadmap' && !isRoadmapEnabled) {
          if (!getPermission().canReadSettings) {
            setLimitationsModal({ action: 'SETTINGS_READ' });
            return;
          }
          navigate(PageId.SettingsFeatures);
          return;
        }

        if (!option.data) return;
        const url = getUrl(getSectionPageId(option.data));
        if (event?.metaKey) {
          window.open(url, '_blank');
        } else {
          history.push(url);
        }
      }}
    />
  );
};
