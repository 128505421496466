import { SectionType } from '@cycle-app/graphql-codegen';
import { Icon } from '@cycle-app/ui';
import { BulbIcon } from '@cycle-app/ui/icons';

import { MainPageId, PageId } from 'src/constants/routing.constant';

export type SectionParent = 'inbox' | 'insight' | 'roadmap';

export const getSectionName = (sectionParent: SectionParent, plural = false) => {
  if (sectionParent === 'inbox') return 'Feedback';
  if (sectionParent === 'insight') return plural ? 'Insights' : 'Insight';
  return plural ? 'Features' : 'Feature';
};

export const getSectionIcon = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return <Icon name="feedback" />;
  if (sectionParent === 'insight') return <BulbIcon />;
  return <Icon name="feature" />;
};

export const getSectionType = (sectionParent: SectionParent) => {
  if (sectionParent === 'inbox') return SectionType.Feedback;
  if (sectionParent === 'insight') return SectionType.Insights;
  return SectionType.Roadmaps;
};

export const getViewPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.InboxView;
  if (sectionParent === 'insight') return PageId.InsightView;
  return PageId.RoadmapView;
};

export const getSectionPageId = (sectionParent: SectionParent): MainPageId => {
  if (sectionParent === 'inbox') return PageId.Inbox;
  if (sectionParent === 'insight') return PageId.Insight;
  return PageId.Roadmap;
};
