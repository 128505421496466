import { Icon } from '@cycle-app/ui';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useProductAreaAttribute } from 'src/hooks/api/useAttributes';
import { commandProductAreaFilter } from 'src/reactives/commandbar.reactive';
import { Layer } from 'src/types/layers.types';

import { FilterButton, ArrowIcon } from './CommandProductAreaFilter.styles';
import { useProductAreasCategories } from '../../../../hooks/product/useProductAreasCategories';
import { ProductAreasManager } from '../../../ProductAreasManager';

export const CommandProductAreaFilter = () => {
  const filter = commandProductAreaFilter.hook();
  const { areas } = useProductAreasCategories();
  const { attributeName } = useProductAreaAttribute();
  const total = areas.length;
  const selectedValues = areas.filter(area => filter.ids.includes(area.id));
  const selectedCount = selectedValues.length;
  if (!total) return null;

  return (
    <ToggleDropdown
      layer={Layer.Commandbar}
      placement="bottom-start"
      button={props => (
        <FilterButton {...props}>
          <Icon name="tri-shapes" />
          {!selectedCount || total === selectedCount ? 'All' : selectedCount}
          {' '}
          {attributeName}
          <ArrowIcon size={16} />
        </FilterButton>
      )}
      content={() => (
        <ProductAreasManager
          docTypeName={undefined}
          compatibleIds={undefined}
          hideIncompatibleValues={false}
          isRequired={false}
          isMulti
          selectedValues={selectedValues.map(a => a.id)}
          onSelect={option => commandProductAreaFilter.produce(current => {
            current.ids = Array.from(new Set([...current.ids, option.productAreaId]));
          })}
          onUnselect={option => commandProductAreaFilter.produce(current => {
            current.ids = current.ids.filter(id => id !== option.productAreaId);
          })}
        />
      )}
    />
  );
};
