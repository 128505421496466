import { ComponentProps, memo } from 'react';
import { twJoin } from 'tailwind-merge';

import { QuoteContentPreview } from 'src/components/QuoteContentPreview';
import { useDocBase } from 'src/hooks/api/useDoc';
import { openQuoteModal } from 'src/hooks/modals/useQuoteModal';

import { QuoteAiState } from './QuoteAiState';
import { QuoteCustomer } from './QuoteCustomer';
import { QuoteFeature } from './QuoteFeature';
import { QuoteFeedback } from './QuoteFeedback';
import { QuoteProperties } from './QuoteProperties';
import { QuoteReporter } from './QuoteReporter';
import { QuoteSource } from './QuoteSource';
import { QuoteOptions } from '../QuotePage/QuoteOptions';

type Props = ComponentProps<'div'> & {
  docId: string;
  showCustomer?: boolean;
  showFeature?: boolean;
};

export const QuoteCard = memo(({
  docId,
  showCustomer = false,
  showFeature = false,
  onClick,
  className,
  ...props 
}: Props) => {
  const { doc } = useDocBase(docId);
  if (!doc) return null;
  const feedbackDocId = doc.docSource?.doc?.id;
  const docSourceId = doc.docSource?.id;
  return (
    <div
      className={twJoin(
        'flex h-fit cursor-pointer flex-col gap-2 overflow-hidden rounded-lg p-2',
        'group/quote bg-white hover:bg-grey-100/25 dark:bg-grey-850/50 hover:dark:bg-grey-850/75',
        'shadow-z1 !outline !outline-1 !outline-black/[0.03] focus-visible:ring dark:outline-white/[0.05]',
        className,
      )}
      role="button"
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        openQuoteModal(docId, null, e.metaKey);
        onClick?.(e);
      }}
      {...props}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="-m-0.5 flex items-center gap-1 overflow-hidden p-0.5">
          {showCustomer && <QuoteCustomer docId={docId} showLabel={!showFeature} />}
          {showFeature && <QuoteFeature docId={docId} />}
        </div>
        
        <div className="flex grow items-center justify-end gap-2">
          <QuoteProperties docId={docId} />
          <QuoteAiState docId={docId} />
          <QuoteSource docId={docId} />
          <QuoteReporter docId={docId} />
          {feedbackDocId && <QuoteFeedback docId={feedbackDocId} />}
          <QuoteOptions docId={docId} className="size-5" />
        </div>
      </div>

      {docSourceId && (
        <QuoteContentPreview
          docId={docId}
          docSourceId={docSourceId}
          showFiles
        />
      )}
    </div>
  );
});
