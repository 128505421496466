import { CompanyFullFragment } from '@cycle-app/graphql-codegen/generated';
import { InfiniteScroll } from '@cycle-app/ui';
import { formatCount, nodeToArray } from '@cycle-app/utilities';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { twJoin } from 'tailwind-merge';

import { CompanyActionsMenu } from 'src/components/CustomersList/CompanyActionsMenu';
import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import { useProduct } from 'src/hooks/api/useProduct';

import { CellCompanyLinkedProfiles } from './CellCompanyLinkedProfiles';
import { CellCustomAttribute } from './CellCustomAttribute';
import { searchAtom } from './customers.atoms';
import { CellCompany, CellCustomId } from './CustomersCells';
import { Table, Column } from './Table';

export const TableCompanies = () => {
  const searchText = useAtomValue(searchAtom);
  
  const query = useCompanies({ defaultSearch: searchText });
  const empty = query.companies.length === 0;
  const loading = (empty && query.isLoading) || query.fetchingMore;
  const fetching = !empty && query.isLoading;
  const hasNextPage = !!query.pageInfo?.hasNextPage;

  const { product } = useProduct();

  const columns = useMemo(() => {
    const cols: Column<CompanyFullFragment>[] = [{
      id: 'name',
      header: <span className="px-2">Name</span>,
      cell: company => <CellCompany company={company} />,
      className: 'w-1/5 min-w-48',
    }, {
      id: 'people',
      header: 'People',
      cell: company => formatCount(company.countCustomers),
      className: 'min-w-28',
    }, {
      id: 'arr',
      header: 'ARR',
      cell: company => formatArr(company.arr),
      className: 'min-w-28',
    }, {
      id: 'employees',
      header: 'Employees',
      cell: company => formatEmployees(company.numberOfEmployees),
      className: 'min-w-28',
    }, {
      id: 'quoteCount',
      header: '# quotes',
      cell: company => formatCount(company.nbInsights ?? 0),
      className: 'min-w-28',
    }, {
      id: 'linkedProfiles',
      header: 'Linked profiles',
      cell: company => <CellCompanyLinkedProfiles company={company} />,
      className: 'min-w-32',
    }, {
      id: 'customId',
      header: 'Custom ID',
      cell: company => <CellCustomId customId={company.customId} />,
      className: 'min-w-32',
    },
    ];

    for (const definition of nodeToArray(product?.companyAttributeDefinitions)) {
      if ('id' in definition) {
        cols.push({
          id: definition.id,
          header: definition.name,
          cell: company => (
            <CellCustomAttribute
              name={definition.name}
              definitionId={definition.id}
              color={definition.color}
              isSelectDefiniton={definition.__typename === 'AttributeSingleSelectDefinition'}
              company={company}
            />
          ),
          className: 'min-w-32',
        });
      }
    }

    cols.push({
      id: 'actions',
      header: '',
      cell: company => <CompanyActionsMenu company={company} />,
      className: 'w-8',
    });

    return cols;
  }, [product]);

  return (
    <div className="relative size-full overflow-hidden bg-inherit pl-4">
      <InfiniteScroll
        isLoading={loading}
        hasMoreData={hasNextPage}
        loadMore={query.fetchNextPage}
        className={twJoin(
          'shy-scrollbar h-full bg-inherit pr-4',
          !hasNextPage && 'pb-40',
        )}
      >
        <Table
          columns={columns}
          data={query.companies}
          loading={loading}
          fetching={fetching}
          stickyRowHeader
        />
      </InfiniteScroll>
    </div>
  );
};

const formatArr = (value: number | null | undefined) => {
  if (value == null) return null;
  if (!Number.isFinite(value)) return value;
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);
};

const formatEmployees = (value: number | null | undefined) => {
  if (value == null) return null;
  if (!Number.isFinite(value)) return value;
  return formatCount(value);
};
